import { useRouter } from 'next/router';

import { LoadingOverlay } from '@/components/ui';
import { ROUTE } from '@/constants/route';
import { useUser } from '@/hooks/features/auth';

export const TopPage = () => {
  const router = useRouter();
  const {
    isLoggedIn,
    isJuror,
    isKgAdmin,
    isKgInformer,
    isOrgAdmin,
    isLoading,
  } = useUser();
  if (isLoading) return <LoadingOverlay isLoading />;

  if (isKgAdmin || isKgInformer || isOrgAdmin) {
    router.replace(ROUTE.contestList());
    return <LoadingOverlay isLoading />;
  } else if (isJuror) {
    router.replace(ROUTE.assignedScreeningList());
    return <LoadingOverlay isLoading />;
  } else if (isLoggedIn) {
    router.replace(ROUTE.organization());
  }

  router.replace(ROUTE.about());
  return <LoadingOverlay isLoading />;
};
